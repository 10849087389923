<template>
  <div :class="isPc ? 'anti-fake-pc' : 'anti-fake-phone'">
    <div class="content-wrap">
      <div class="swiper-wrap">
        <van-swipe
          ref="swiper"
          class="swipe"
          :autoplay="5000"
          indicator-color="white"
        >
          <!-- <van-swipe-item v-for="item in swiperData" :key="item.id"
          ><img :src="item.pic" alt=""
        /></van-swipe-item> -->
          <van-swipe-item v-for="item in imgs" :key="item.url">
            <img :src="item.url" alt="" />
          </van-swipe-item>
        </van-swipe>
        <div class="back" @click="goBack"></div>
        <div class="next" @click="next"></div>
      </div>
      <div class="form-content">
        <div class="title-wrap">
          <div class="title">
            防串码查询
          </div>
          <div class="remark">
            您所购买酒品的防伪标识带有银色刮开涂层，方可使用下方的对话框查询真伪。如在使用过程中遇到疑问，请与我们联系。（联系电话：400-820-919）
          </div>
        </div>
        <div class="input-wrap">
          <div class="title">
            <span
              :class="antCodeNum == 16 ? 'selected' : ''"
              @click="antCodeNum = 16"
              >16位防串码</span
            >
            <span
              :class="antCodeNum == 22 ? 'selected' : ''"
              @click="antCodeNum = 22"
              >22位防串码</span
            >
          </div>
          <div class="ant-num">
            <input type="text" v-model="antNum" placeholder="请输入防串码" />
            <span class="icon" v-if="antNum" @click="antNum = ''"></span>
          </div>
          <div class="code-num">
            <input
              type="text"
              v-model="code"
              class="cod-num"
              placeholder="请输入验证码"
            />
            <div class="code" @click="refreshCode">
              <sidentify :identifyCode="identifyCode" />
              <span>看不清，换一个</span>
            </div>
          </div>
          <div class="btn" @click="search">
            查 询
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidentify from "@/components/identify";
export default {
  name: "anti-fake",
  props: ["isPc"],
  components: {
    sidentify
  },
  computed: {
    imgs() {
      let data = [];
      if (!this.isPc) {
        data = [
          { url: require("../../assets/img/anti-fake-1.png") },
          { url: require("../../assets/img/anti-fake-2.png") },
          { url: require("../../assets/img/anti-fake-3.png") }
        ];
      } else {
        data = [
          { url: require("../../assets/img-pc/anti-fake-1.png") },
          { url: require("../../assets/img-pc/anti-fake-2.png") },
          { url: require("../../assets/img-pc/anti-fake-3.png") }
        ];
      }
      return data;
    }
  },
  data() {
    return {
      antNum: "",
      code: "",
      antCodeNum: 16,
      identifyCode: "",
      identifyCodes: "1234567890abcdefghijklmnopqistuvwxyz"
    };
  },

  methods: {
    search() {
      if (!this.antNum) {
        return this.$message.error("防串码不能为空！");
      }
      if (!this.code) {
        return this.$message.error("验证码不能为空！");
      } else if (this.code != this.identifyCode) {
        return this.$message.error("验证码错误！");
      }
      window.localStorage.setItem("antNum", this.antNum);
      this.$router.push("/anti-result");
    },
    goBack() {
      this.$refs.swiper.prev();
      this.$refs.swiper.resize();
    },
    next() {
      this.$refs.swiper.next();
      this.$refs.swiper.resize();
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    hasFcm() {
      let fcm = this.$route.query.fcm;
      if (fcm) {
        this.antNum = fcm;
        this.code = this.identifyCode;
        this.search();
      }
    }
  },

  created() {
    this.refreshCode();
    // 其他情况扫描防串码进入此页面 直接查询结果
    this.hasFcm();
  }
};
</script>

<style lang="less" scoped>
@import url("./index.less");
.anti-fake-phone {
  .content-wrap {
    .swiper-wrap {
      height: 1269px;
      position: relative;
      .back,
      .next {
        width: 60px;
        height: 60px;
        // background-color: #333333;
        opacity: 0.3;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all linear 0.8s;
        background-size: contain;
        // display: none;
      }
      .back {
        background-image: url(../../assets/img-pc/back.png);
        left: 30px;
      }
      .next {
        background-image: url(../../assets/img-pc/next.png);
        right: 30px;
      }
    }

    .form-content {
      padding: 30px 60px 60px 72px;
      text-align: center;
      .title-wrap {
        padding-top: 40px;
        .title {
          color: #c90f2c;
          font-size: 72px;
          margin-bottom: 30px;
        }
        .remark {
          font-size: 32px;
          color: #333333;
          line-height: 54px;
        }
      }

      .input-wrap {
        width: 952px;
        height: 498px;
        margin-top: 60px;
        .title {
          font-size: 30px;
          line-height: 48px;
          color: #999999;
          margin-bottom: 26px;
          text-align: left;
          span {
            cursor: pointer;
            &.selected {
              color: #c90f2c;
            }
          }
          span:nth-child(1) {
            margin-right: 60px;
          }
        }

        .code-num,
        .ant-num {
          input {
            box-sizing: border-box;
            width: 951px;
            height: 111px;
            background-color: #ffffff;
            padding-left: 43px;
            border: 3px solid #f1f1ef;
            border-radius: 12px;
            font-size: 30px;
            &:first-child {
              margin-bottom: 50px;
            }
          }
        }

        .ant-num {
          position: relative;
          .icon {
            width: 37px;
            height: 37px;
            background: url("../../assets/img-pc/clear.png") no-repeat;
            background-size: contain;
            position: absolute;
            right: 38px;
            top: 55px;
            transform: translateY(-50%);
          }
        }

        .code-num {
          display: flex;
          justify-content: space-between;
          input {
            width: 652px;
          }
          .code {
            width: 263px;
            height: 111px;
            text-align: center;
            /deep/ #s-canvas {
              width: 100%;
              height: 111px;
            }
            span {
              display: none;
            }
          }
        }

        .btn {
          width: 951px;
          height: 111px;
          background-color: #c90f2c;
          border-radius: 12px;
          font-size: 48px;
          color: #ffffff;
          line-height: 111px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
